<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            label="기준월"
            name="measureYear"
            type="month"
            default="today"
            v-model="searchParam.measureYear"
          />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="배출시설별 제품생산량"
          tableId="processManual"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :merge="grid.merge"
          :columnSetting="false"
          :isFullScreen="true"
          :usePaging="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="등록" icon="add" @btnClicked="linkClick" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'daily-air-report-manage',
  data() {
    return {
      col1Items: [
        { code: '1', codeName: '고체연료연소' },
        { code: '2', codeName: '기체연료연소' },
        { code: '2', codeName: '액체연료연소' },
      ],
      col2Items: [
        { code: '1', codeName: '공정시설' },
        { code: '2', codeName: '일반보일러실' },
        { code: '3', codeName: '기타' },
      ],
      grid: {
        merge: [
          { index: 0, colName: 'col2' },
          { index: 1, colName: 'col2' },
          { index: 2, colName: 'col2' },
        ],
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '사업장',
            align: 'center',
            sortable: true,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '생산월',
            align: 'center',
            sortable: true,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '배출시설',
            align: 'center',
            type: 'link',
            sortable: true,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '제품명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '생산량',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        col1: null,
        col2: null,
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '80%',
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          col1: '사업장1', 
          col2: '2022-11', 
          col3: '공정연소시설 #021', 
          col4: '제품1', 
          col5: '90', 
        },
        {
          col1: '사업장1', 
          col2: '2022-11', 
          col3: '공정연소시설 #021', 
          col4: '제품2', 
          col5: '142', 
        },
        {
          col1: '사업장1', 
          col2: '2022-10', 
          col3: '공정연소시설 #021', 
          col4: '제품1', 
          col5: '135', 
        },
        {
          col1: '사업장1', 
          col2: '2022-10', 
          col3: '공정연소시설 #021', 
          col4: '제품2', 
          col5: '99', 
        },
      ];
    },
    linkClick(row) {
      this.detailPopup(row);
    },
    detailPopup(result) {
      this.popupOptions.target = () => import(`${"./emissionsProductDetail.vue"}`);
      this.popupOptions.title = '시설별 제품생산량 상세';
      this.popupOptions.param = {
        psiDocumentId: result,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
